import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  typography: {
    fontFamily: "'Poppins', sans-serif",
  },
  palette: {
    primary: {
      main: "#252525",
    },
  },
  components: {
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: "#252525",
          },
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          "&.Mui-focused": {
            color: "#252525",
          },
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          fontSize: 16,
        },
        head: {
          backgroundColor: "#f5f5f5",
          color: "#000",
        },
      },
    },
    MuiTableHead: {
      styleOverrides: {
        root: {
          backgroundColor: "#f5f5f5",
        },
      },
    },
    MuiTableContainer: {
      styleOverrides: {
        root: {
          borderTop: "1px solid #EEE",
          borderRight: "1px solid #EEE",
          borderLeft: "1px solid #EEE",
          marginTop: 20,
          borderRadius: 15,
          maxHeight: "70vh",
          overflowY: "auto",
          overflowX: "auto",
        },
      },
    },

    MuiDialog: {
      styleOverrides: {
        paper: {
          width: 500,
          maxWidth: "100%",
        },
      },
    },
  },
});

export default theme;
