import React, { useEffect, useState } from "react";
import { IoImageOutline } from "react-icons/io5";
import Header from "components/Header";
import CModal from "components/Modal";
import Input from "components/Input";
import useDialog from "../hooks/useDialog";
import DynamicTable from "components/Table";
import { Modal } from "@mui/material";
import Layout from "components/Layout";
import { axiosPrivate } from "services/axiosPrivate";

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "80%",
  maxHeight: "90%",
  overflow: "auto",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const Projects = () => {
  const { modals, handleClose, handleOpen } = useDialog();
  const [projects, setProjects] = useState([]);
  const [update, setUpdate] = useState(0);
  const [currentProjectId, setCurrentProjectId] = useState(null);
  const [selectedImage, setSelectedImage] = useState("");

  useEffect(() => {
    axiosPrivate
      .get("/projects")
      .then((res) => {
        const sortedProjects = res.data.sort((a, b) => a.order - b.order);
        setProjects(sortedProjects);
      })
      .catch((error) => {
        console.error("Error fetching projects", error);
      });
  }, [update]);

  const handleImageClick = (imgURL) => {
    setSelectedImage(imgURL);
    handleOpen("image");
  };

  const [newForm, setNewForm] = useState({
    name: "",
    desc: "",
    to: "",
    img: null,
    order: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewForm({ ...newForm, [name]: value });
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setNewForm({
        ...newForm,
        img: file,
      });
    }
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append("name", newForm.name);
    formData.append("desc", newForm.desc);
    formData.append("to", newForm.to);
    formData.append("order", newForm.order);

    if (newForm.img) {
      formData.append("img", newForm.img);
    }

    try {
      if (currentProjectId) {
        await axiosPrivate.put(`/projects/${currentProjectId}`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
      } else {
        await axiosPrivate.post("/projects", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
      }

      setNewForm({
        name: "",
        desc: "",
        to: "",
        img: null,
        order: "",
      });
      setCurrentProjectId(null);
      setUpdate(update + 1);
      handleClose("project");
    } catch (error) {
      console.error("Error adding/updating project", error);
    }
  };

  const deleteProjects = (id) =>
    axiosPrivate.delete(`/projects/${id}`).then(() => setUpdate(update + 1));

  const handleEdit = (row) => {
    setNewForm({
      name: row.name,
      desc: row.desc,
      to: row.to,
      img: row.img,
      order: row.order,
    });
    setCurrentProjectId(row._id);
    handleOpen("project");
  };

  const columns = [
    {
      label: "Image",
      field: "img",
      render: (value) => (
        <img
          className="w-[50px] h-[50px] object-contain cursor-pointer"
          src={`${process.env.REACT_APP_API_URL}/${value}`}
          alt=""
          onClick={() =>
            handleImageClick(`${process.env.REACT_APP_API_URL}/${value}`)
          }
        />
      ),
    },
    { label: "Name", field: "name" },
    { label: "Description", field: "desc" },
    { label: "Link", field: "to" },
    { label: "Order", field: "order" },
  ];

  return (
    <Layout>
      <Header title="Projects" onClick={() => handleOpen("project")} />

      <DynamicTable
        data={projects}
        columns={columns}
        onDelete={deleteProjects}
        onEdit={handleEdit}
      />

      <CModal
        title={currentProjectId ? "Edit Project" : "New Project"}
        open={modals.project}
        handleClose={() => {
          handleClose("project");
          setCurrentProjectId(null);
          setNewForm({
            name: "",
            desc: "",
            to: "",
            img: null,
            order: "",
          });
        }}
        handleConfirm={handleFormSubmit}
        content={
          <div className="flex flex-col gap-4 w-full">
            <Input
              type="file"
              name="img"
              accept="image/*"
              label="Project Image"
              frontIcon={<IoImageOutline className="text-gray-500 text-3xl" />}
              onChange={handleImageChange}
            />
            <Input
              label="Name"
              name="name"
              value={newForm.name}
              onChange={handleInputChange}
            />
            <Input
              label="Description"
              name="desc"
              multiline
              maxRows={4}
              value={newForm.desc}
              onChange={handleInputChange}
            />
            <Input
              label="Link"
              name="to"
              value={newForm.to}
              onChange={handleInputChange}
            />
            <Input
              label="Order"
              name="order"
              value={newForm.order}
              onChange={handleInputChange}
            />
          </div>
        }
      />

      <Modal open={modals.image} onClose={() => handleClose("image")}>
        <div style={modalStyle}>
          <img
            src={selectedImage}
            alt="Fullscreen view"
            className="w-full h-auto"
          />
        </div>
      </Modal>
    </Layout>
  );
};

export default Projects;
