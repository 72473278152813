import { create } from "zustand";

const useDialogState = create((set) => ({
  modals: {
    project: false,
    service: false,
    image: false,
    faq: false,
  },
  type: undefined,
  setOpen: (modal, open, type) =>
    set((state) => ({
      modals: {
        ...state.modals,
        [modal]: open,
      },
      type,
    })),
  handleOpen: (modal, type) =>
    set((state) => ({
      modals: {
        ...state.modals,
        [modal]: true,
      },
      type,
    })),
  handleClose: (modal) =>
    set((state) => ({
      modals: {
        ...state.modals,
        [modal]: false,
      },
      type: undefined,
    })),
}));

export default useDialogState;
