import * as React from "react";
import Dialog from "@mui/material/Dialog";
import Slide from "@mui/material/Slide";
import { Button } from "@headlessui/react";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function Modal({
  open,
  handleClose,
  title,
  content,
  handleConfirm,
}) {
  return (
    <React.Fragment>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
      >
        <div className="flex flex-col gap-4 p-8">
          <p className="font-normal text-2xl mb-2">{title}</p>
          <div>{content}</div>
          <div className="w-full flex items-center justify-between gap-4">
            <Button
              className="rounded-md bg-gray-700 py-2.5 px-5 text-white hover:opacity-80 w-full"
              onClick={handleClose}
            >
              Close
            </Button>
            <Button
              className="rounded-md bg-green-700 py-2.5 px-5 text-white hover:opacity-80 w-full"
              onClick={handleConfirm}
            >
              Save
            </Button>
          </div>
        </div>
      </Dialog>
    </React.Fragment>
  );
}
