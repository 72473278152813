import { useNavigate } from "react-router-dom";
import { axiosPrivate } from "services/axiosPrivate";
import { useEffect } from "react";

const setupInterceptors = (token, navigate) => {
  axiosPrivate.interceptors.request.use(
    (config) => {
      const token = localStorage.getItem("token");
      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  axiosPrivate.interceptors.response.use(
    (response) => response,
    async (error) => {
      const prevRequest = error?.config;
      if (error?.response?.status === 401 || error?.response?.status === 404) {
        prevRequest.sent = true;
        localStorage.removeItem("token");
        navigate("/");
        return axiosPrivate(prevRequest);
      } else {
        return Promise.reject(error);
      }
    }
  );
};

const Auth = ({ children }) => {
  const navigate = useNavigate();
  const token = localStorage.getItem("token");

  useEffect(() => {
    if (token === null) {
      navigate("/");
    } else {
      setupInterceptors(token, navigate);
    }
  }, [token, navigate]);

  return children;
};

export default Auth;
