import useSidebar from "hooks/useSidebar";
import NavBar from "./Navigation";
import Sidebar from "./Sidebar";
import Auth from "services/Auth";
import { useNavigate } from "react-router-dom";

export default function Layout(props) {
  const navigate = useNavigate();
  const { isSidebarOpen, toggleSidebar } = useSidebar();
  return (
    <Auth>
      <div className="flex">
        <Sidebar isSidebarOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />
        <div className="flex-1 flex flex-col">
          <NavBar toggleSidebar={toggleSidebar} navigate={navigate} />
          <div className="p-8 flex-1">{props.children}</div>
        </div>
      </div>
    </Auth>
  );
}
