import React, { useEffect, useState } from "react";
import { IoImageOutline } from "react-icons/io5";
import DynamicTable from "components/Table";
import Header from "components/Header";
import Modal from "components/Modal";
import Input from "components/Input";
import useDialog from "../hooks/useDialog";
import Layout from "components/Layout";
import { axiosPrivate } from "services/axiosPrivate";

const Services = () => {
  const { modals, handleClose, handleOpen } = useDialog();
  const [services, setServices] = useState([]);
  const [update, setUpdate] = useState(0);
  const [currentServiceId, setCurrentServiceId] = useState(null);

  useEffect(() => {
    axiosPrivate.get("/services").then((res) => setServices(res.data));
  }, [update]);

  const [newForm, setNewForm] = useState({
    name: "",
    desc: "",
    icon: null,
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewForm({ ...newForm, [name]: value });
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setNewForm({
        ...newForm,
        icon: file,
      });
    }
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append("name", newForm.name);
    formData.append("desc", newForm.desc);

    if (newForm.icon) {
      formData.append("img", newForm.icon);
    }

    try {
      if (currentServiceId) {
        await axiosPrivate.put(`/services/${currentServiceId}`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
      } else {
        await axiosPrivate.post("/services", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
      }

      setNewForm({
        name: "",
        desc: "",
        icon: null,
      });
      setCurrentServiceId(null);
      setUpdate(update + 1);
      handleClose("service");
    } catch (error) {
      console.error("Error adding/updating service", error);
    }
  };

  const deleteServices = (id) =>
    axiosPrivate.delete(`/services/${id}`).then(() => setUpdate(update + 1));

  const handleEdit = (row) => {
    setNewForm({
      name: row.name,
      desc: row.desc,
      icon: row.icon,
    });
    setCurrentServiceId(row._id);
    handleOpen("service");
  };

  const columns = [
    {
      label: "Icon",
      field: "icon",
      render: (value) => (
        <img
          className="w-[30px]"
          src={`${process.env.REACT_APP_API_URL}/${value}`}
          alt="Icon"
        />
      ),
    },
    { label: "Name", field: "name" },
    { label: "Description", field: "desc" },
  ];

  return (
    <Layout>
      <Header title="Services" onClick={() => handleOpen("service")} />

      <DynamicTable
        data={services}
        columns={columns}
        onDelete={deleteServices}
        onEdit={handleEdit}
      />

      <Modal
        title={currentServiceId ? "Edit Service" : "New Service"}
        open={modals.service}
        handleClose={() => {
          handleClose("service");
          setCurrentServiceId(null);
        }}
        handleConfirm={handleFormSubmit}
        content={
          <div className="flex flex-col gap-4 w-full">
            <Input
              type="file"
              name="icon"
              accept="image/*"
              label="Icon"
              frontIcon={<IoImageOutline className="text-gray-500 text-3xl" />}
              onChange={handleImageChange}
            />

            <Input
              label="Name"
              name="name"
              value={newForm.name}
              onChange={handleInputChange}
            />
            <Input
              label="Description"
              name="desc"
              multiline
              maxRows={4}
              value={newForm.desc}
              onChange={handleInputChange}
            />
          </div>
        }
      />
    </Layout>
  );
};

export default Services;
