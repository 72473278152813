import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import { BiUser } from "react-icons/bi";
import { MdOutlineModeEditOutline } from "react-icons/md";
import Input from "components/Input";
import Layout from "components/Layout";
import { axiosPrivate } from "services/axiosPrivate";

function Dashboard() {
  const [about, setAbout] = useState("");
  const [originalAbout, setOriginalAbout] = useState("");
  const [isEditing, setIsEditing] = useState(false);

  useEffect(() => {
    axiosPrivate.get("/about-me").then((res) => {
      setAbout(res.data?.content);
      setOriginalAbout(res.data?.content);
    });
  }, []);

  const updateAboutMe = () => {
    if (about !== originalAbout) {
      axiosPrivate.put("/about-me", { content: about }).then((res) => {
        setIsEditing(false);
        setOriginalAbout(about);
      });
    } else {
      setIsEditing(false);
    }
  };

  return (
    <Layout>
      <h1 className="font-light mb-6 text-4xl">Dashboard</h1>
      <Input
        name="about"
        multiline={true}
        maxRows={5}
        value={about}
        onChange={(e) => setAbout(e.target.value)}
        frontIcon={<BiUser className="text-gray-500 text-3xl" />}
        endIcon={
          isEditing ? (
            <Button
              variant="contained"
              color="primary"
              onClick={updateAboutMe}
              disabled={about === originalAbout}
            >
              Save
            </Button>
          ) : (
            <MdOutlineModeEditOutline
              className="text-blue-500 text-3xl"
              onClick={() => setIsEditing(true)}
            />
          )
        }
        readOnly={!isEditing}
      />
    </Layout>
  );
}

export default Dashboard;
