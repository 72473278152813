import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { MdDelete, MdOutlineModeEditOutline } from "react-icons/md";

const DynamicTable = ({ columns, data, onEdit, onDelete }) => {
  return (
    <TableContainer>
      <Table stickyHeader>
        <TableHead>
          <TableRow>
            {columns.map((column, index) => (
              <TableCell key={index} align="left">
                {column.label}
              </TableCell>
            ))}
            <TableCell align="left"></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row, rowIndex) => (
            <TableRow key={rowIndex} hover>
              {columns.map((column, colIndex) => (
                <TableCell key={colIndex} align="left">
                  {column.render
                    ? column.render(row[column.field], row)
                    : row[column.field]}
                </TableCell>
              ))}
              <TableCell align="left">
                <div className="flex items-center gap-2">
                  <MdOutlineModeEditOutline
                    className="text-2xl text-blue-500"
                    onClick={() => onEdit(row)}
                  />
                  <MdDelete
                    className="text-2xl text-red-500"
                    onClick={() => onDelete(row?._id)}
                  />
                </div>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default DynamicTable;
