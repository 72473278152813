import React, { useEffect, useState } from "react";
import DynamicTable from "components/Table";
import Header from "components/Header";
import Modal from "components/Modal";
import Input from "components/Input";
import useDialog from "../hooks/useDialog";
import Layout from "components/Layout";
import { axiosPrivate } from "services/axiosPrivate";

const Faq = () => {
  const { modals, handleClose, handleOpen } = useDialog();
  const [faqs, setFaqs] = useState([]);
  const [update, setUpdate] = useState(0);
  const [currentFaqId, setCurrentFaqId] = useState(null);

  useEffect(() => {
    axiosPrivate.get("/faq").then((res) => setFaqs(res.data));
  }, [update]);

  const [newForm, setNewForm] = useState({
    question: "",
    answer: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewForm({ ...newForm, [name]: value });
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    try {
      if (currentFaqId) {
        await axiosPrivate.put(`/faq/${currentFaqId}`, newForm);
      } else {
        await axiosPrivate.post("/faq", newForm);
      }

      setNewForm({
        question: "",
        answer: "",
      });
      setCurrentFaqId(null);
      setUpdate(update + 1);
      handleClose("faq");
    } catch (error) {
      console.error("Error adding/updating FAQ", error);
    }
  };

  const deleteFaqs = (id) =>
    axiosPrivate.delete(`/faq/${id}`).then(() => setUpdate(update + 1));

  const handleEdit = (row) => {
    setNewForm({
      question: row.question,
      answer: row.answer,
    });
    setCurrentFaqId(row._id);
    handleOpen("faq");
  };

  const columns = [
    { label: "Question", field: "question" },
    { label: "Answer", field: "answer" },
  ];

  return (
    <Layout>
      <Header title="FAQ" onClick={() => handleOpen("faq")} />

      <DynamicTable
        data={faqs}
        columns={columns}
        onDelete={deleteFaqs}
        onEdit={handleEdit}
      />

      <Modal
        title={currentFaqId ? "Edit FAQ" : "New FAQ"}
        open={modals.faq}
        handleClose={() => {
          handleClose("faq");
          setCurrentFaqId(null);
        }}
        handleConfirm={handleFormSubmit}
        content={
          <div className="flex flex-col gap-4 w-full">
            <Input
              label="Question"
              name="question"
              multiline={true}
              maxRows={2}
              value={newForm.question}
              onChange={handleInputChange}
            />
            <Input
              label="Answer"
              name="answer"
              multiline={true}
              maxRows={4}
              value={newForm.answer}
              onChange={handleInputChange}
            />
          </div>
        }
      />
    </Layout>
  );
};

export default Faq;
