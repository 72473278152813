import { RiMenuUnfoldLine } from "react-icons/ri";

function Navigation(props) {
  function logout() {
    localStorage.removeItem("token");
    props.navigate("/");
  }
  return (
    <div className="bg-white border-b border-b-[#EEE] py-4 px-8 flex justify-between items-center">
      <RiMenuUnfoldLine
        onClick={props.toggleSidebar}
        className="text-gray-500 text-2xl"
      />
      <img
        onClick={logout}
        src="https://sb.kaleidousercontent.com/67418/1920x1281/0e9f02a048/christian-buehner-ditylc26zvi-unsplash.jpg"
        style={{ width: 50, height: 50, borderRadius: 50 }}
        alt=""
      />
    </div>
  );
}
export default Navigation;
