import { Button } from "@headlessui/react";
import axios from "axios";
import Input from "components/Input";
import { Logo } from "components/Logo";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";

const Auth = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const togglePasswordVisibility = () => setShowPassword((prev) => !prev);

  const loginSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    try {
      const res = await axios.post("/auth/login", {
        username: username,
        password: password,
      });
      localStorage.setItem("token", res.data.token);
      setLoading(false);
      navigate("/dashboard");
    } catch (err) {
      setLoading(false);
    }
  };

  return (
    <div className="min-h-[95vh] flex items-center justify-center bg-white">
      <div className="max-w-md w-full p-6 sm:p-12 sm:rounded-lg">
        <div className="border border-gray-200 rounded-lg p-6 flex flex-col items-center">
          <Logo />
          <form
            onSubmit={loginSubmit}
            className="w-full flex flex-col items-center gap-4 mt-10"
          >
            <Input
              label="Username"
              name="username"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
            />
            <Input
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              label="Password"
              name="password"
              type={showPassword ? "text" : "password"}
              endIcon={
                <span
                  onClick={togglePasswordVisibility}
                  className="cursor-pointer"
                >
                  {showPassword ? (
                    <AiFillEyeInvisible className="text-primary text-2xl" />
                  ) : (
                    <AiFillEye className="text-primary text-2xl" />
                  )}
                </span>
              }
            />
            <Button
              disabled={loading}
              type="submit"
              className="w-full p-3 rounded-md bg-primary text-white hover:opacity-80"
            >
              {loading ? "..." : "Sign In"}
            </Button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Auth;
