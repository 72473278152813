import { Button } from "@headlessui/react";
import React from "react";
import { useNavigate } from "react-router-dom";

const Header = ({ onClick, title, buttonName }) => {
  const navigate = useNavigate();
  return (
    <div className="flex items-center justify-between">
      <button
        onClick={() => navigate("/dashboard")}
        type="button"
        class="bg-white text-center w-48 rounded-2xl h-14 relative text-black text-4xl font-light group"
      >
        <div class="bg-primary rounded-xl h-12 w-1/4 flex items-center justify-center absolute left-1 top-[4px] group-hover:w-[230px] z-10 duration-500">
          <svg
            width="25px"
            height="25px"
            viewBox="0 0 1024 1024"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill="#fff"
              d="M224 480h640a32 32 0 1 1 0 64H224a32 32 0 0 1 0-64z"
            ></path>
            <path
              fill="#fff"
              d="m237.248 512 265.408 265.344a32 32 0 0 1-45.312 45.312l-288-288a32 32 0 0 1 0-45.312l288-288a32 32 0 1 1 45.312 45.312L237.248 512z"
            ></path>
          </svg>
        </div>
        <p class="translate-x-[3.5rem]">{title}</p>
      </button>
      <Button
        className="rounded-md bg-primary py-2.5 px-5 text-white hover:opacity-80"
        onClick={onClick}
      >
        {buttonName ? buttonName : "Add New"}
      </Button>
    </div>
  );
};

export default Header;
