import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL;

const axiosPrivate = axios.create({
  baseURL: API_URL,
  headers: { "Content-Type": "application/json" },
});

export { axiosPrivate };
