import { TextField } from "@mui/material";
import React from "react";

const Input = ({
  value,
  onChange,
  frontIcon,
  endIcon,
  readOnly,
  multiline = false,
  maxRows,
  label,
  type = "text",
  name,
  accept,
}) => {
  return (
    <TextField
      label={label}
      type={type}
      variant="outlined"
      accept={type === "file" && accept}
      name={name}
      className="w-full"
      multiline={multiline}
      maxRows={maxRows}
      value={value}
      onChange={onChange}
      InputProps={{
        startAdornment: frontIcon,
        endAdornment: endIcon,
        readOnly: readOnly,
      }}
    />
  );
};

export default Input;
