import React from "react";
import { Link, useLocation } from "react-router-dom";
import { RiMenuFoldLine } from "react-icons/ri";

function Sidebar(props) {
  const location = useLocation();

  const isActive = (path) => location.pathname === path;

  return (
    <>
      <div
        className={`fixed inset-y-0 left-0 bg-white border-r-[#EEE] w-[20rem] p-6 z-40 transform ${
          props.isSidebarOpen ? "translate-x-0" : "-translate-x-full"
        } transition-transform duration-300 ease-in-out lg:relative lg:translate-x-0 lg:w-[20rem] lg:h-screen lg:p-6`}
      >
        <RiMenuFoldLine
          onClick={props.toggleSidebar}
          className="text-gray-500 text-2xl absolute top-4 right-4 lg:hidden"
        />
        <nav className="mt-6">
          <ul className="flex flex-col gap-2">
            <li>
              <Link
                to="/dashboard"
                className={`block py-2 px-4 rounded ${
                  isActive("/")
                    ? "bg-gray-200 text-gray-700"
                    : "text-gray-700 hover:bg-gray-200"
                }`}
                onClick={props.toggleSidebar}
              >
                Dashboard
              </Link>
            </li>
            <li>
              <Link
                to="/projects"
                className={`block py-2 px-4 rounded ${
                  isActive("/projects")
                    ? "bg-gray-200 text-gray-700"
                    : "text-gray-700 hover:bg-gray-200"
                }`}
                onClick={props.toggleSidebar}
              >
                Projects
              </Link>
            </li>
            <li>
              <Link
                to="/services"
                className={`block py-2 px-4 rounded ${
                  isActive("/services")
                    ? "bg-gray-200 text-gray-700"
                    : "text-gray-700 hover:bg-gray-200"
                }`}
                onClick={props.toggleSidebar}
              >
                Services
              </Link>
            </li>
            <li>
              <Link
                to="/faq"
                className={`block py-2 px-4 rounded ${
                  isActive("/faq")
                    ? "bg-gray-200 text-gray-700"
                    : "text-gray-700 hover:bg-gray-200"
                }`}
                onClick={props.toggleSidebar}
              >
                Faq
              </Link>
            </li>
          </ul>
        </nav>
      </div>

      {/* Overlay for mobile */}
      {props.isSidebarOpen && (
        <div
          className="fixed inset-0 bg-black bg-opacity-50 z-30 lg:hidden"
          onClick={props.toggleSidebar}
        />
      )}
    </>
  );
}

export default Sidebar;
